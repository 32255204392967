import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

const leaveJobServices = {
  /**
   * 业务继承
  */
  async departBizExtend(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/bizExtend`, params).then(thenResolve)
  },
  /**
   * 业务数据列表
  */
  async departBizList(params) {
    return await request.get(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/bizList`, { params }).then(thenResolve)
  },
  /**
   * 群主继承
   */
  async departChatExtend(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/chatExtend`, params).then(thenResolve)
  },
  /**
   * 外部群列表
   */
  async departChatList(params) {
    return await request.get(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/chatList`, { params }).then(thenResolve)
  },
  /**
   * 继承历史
   */
  async departExtendHistory(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/extendHistory`, params).then(thenResolve)
  },
  /**
   * 联系人继承
   */
  async departExternalExtend(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/externalExtend`, params).then(thenResolve)
  },
  /**
   * 外部联系人列表
   */
  async departExternalList(params) {
    return await request.get(`${SERVER.STATISTICS_SERVICE}/api/v1/depart/externalList`, { params }).then(thenResolve)
  },
  /**
   * 查询人员接口
   */
  async getListEmpToMap(params) {
    return await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/employee/qw/listEmpToMap`, { params }).then(thenResolve)
  },
}
export default leaveJobServices
