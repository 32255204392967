<!-- 好友继承 -->
<template>
  <div class="leaveJob-chat">
    <van-collapse ref="vcollapse" v-model="activeLevelNames">
      <van-collapse-item v-for="(item1, index1) in dataSource" :key="index1" class="common-block" :name="index1">
        <template #title>
          <div class="title-s">
            <div class="title-s-l" @click.stop=''>
              <van-checkbox
                checked-color="#B9921A"
                icon-size="16"
                :disabled="item1.datas.length ==0 || calculateDisabled(item1)"
                :value="getCheckBool(item1, index1)"
                @click.stop="checkAll(item1, index1)">
              </van-checkbox>
              <div class="title-s-l-name">{{ `${item1.name}（${item1.total}）` }}</div>
            </div>
            <div class="title-s-r">{{ item1.departureTime | timeFormat('YYYY-MM-DD') }} {{ $t('离职') }}</div>
          </div>
        </template>
        <template #default>
          <van-checkbox-group
            :ref="`checkboxGroup_${index1}`"
            v-model="baseSelectArr[index1]"
            :disabled="calculateDisabled(item1)"
            @change="checkFun($event, item1)">
            <div v-for="(item2, index2) in item1.datas" :key="index2" class="info">
              <div class="info-l">
                <van-checkbox
                  checked-color="#B9921A"
                  icon-size="16"
                  shape="square"
                  :name="item2">
                </van-checkbox>
              </div>
              <div class="info-r">
                <img class="avatar" :src="item2.avatar || noAvatar" width="24px" height="24px"/>
                <span>{{ item2.name }}</span>
                <img class="sex" :src="getSex(item2.gender)" width="14px" height="14px"/>
              </div>
            </div>
          </van-checkbox-group>
        </template>
      </van-collapse-item>
      <Empty v-if="dataSource.length == 0 && queryMark" :description="$t('暂无数据')"></Empty>
    </van-collapse>
  </div>
</template>
<script>
import gender_woman from '@/images/gender_woman.png'
import noAvatar from '@/images/noAvatar.svg'
import gender_unknown from '@/images/gender_unknown.svg'
import gender_man from '@/images/gender_man.png'
export default {
  props: {
    dataSource: {
      type: Array,
      default: () => { [] }
    },
    queryMark: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      gender_woman,
      gender_man,
      gender_unknown,
      noAvatar,
      activeLevelNames: [],
      checkEmpId: '', // 当前选中人员的id
      baseSelectArr: [], // 可选择基础数据合集
      checkData: [], // 已选择数据
    }
  },
  computed: {
    
  },
  watch: {
    dataSource: {
      handler(val) {
        this.activeLevelNames = this.dataSource.map((item, index) => { return index })
        this.baseSelectArr = []
        this.checkData = []
        val.forEach(() => {
          this.baseSelectArr.push([])
        })
      },
      immediate: true,
      deep: true
    },
    baseSelectArr: {
      handler(val) {
        this.checkData = []
        val.forEach(item => {
          if (item.length > 0) {
            this.checkData = this.checkData.concat(item)
          }
        })
      },
      immediate: true,
      deep: true
    },
    checkData: {
      handler(val) {
        if (val.length == 0) {
          this.checkEmpId = ''
        }
        this.$emit('dataChange', val, '')
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getSex(sex) {
      if (!sex) return gender_unknown
      const obj = {
        1: gender_man,
        2: gender_woman,
        0: gender_unknown,
      }
      return obj[sex]
    },
    // 选择业务类型组
    checkAll(data, index1) {
      if (!this.$refs['vcollapse'].$children[0].show) {
        this.$refs['vcollapse'].$children[0].toggle()
      }
      this.$nextTick(() => {
        if (!this.$refs[`checkboxGroup_${index1}`][0].disabled) {
          this.$refs[`checkboxGroup_${index1}`][0].toggleAll(!this.getCheckBool(data, index1))
        }
      })
    },
    // 是否选择
    getCheckBool(item, index1, child='datas') {
      const { baseSelectArr } = this
      if (item[child].length == 0) {
        return false
      }
      if (baseSelectArr[index1] && baseSelectArr[index1].length === item[child].length ){
        return true
      } else {
        return false
      }
    },
    checkFun(data, { empId }) {
      if (this.checkEmpId && this.checkEmpId != empId) {
        return false
      }
      if (data.length) {
        this.checkEmpId = empId
      }
    },
    // 计算是否置灰
    calculateDisabled({ empId }) {
      if (!this.checkEmpId) {
        return false
      }
      return empId !== this.checkEmpId
    }
  }
}
</script>
<style lang="less" scoped>
  .leaveJob-chat{
    .common-block{
      padding: 12px;
      padding-bottom: 0px;
      overflow: hidden;
      .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
        border: 0
      }
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
      
    }
    /deep/ .van-collapse-item{
      .van-checkbox__icon{
        margin-right: 10px;
      }
      .van-checkbox__icon--disabled .van-icon {
        background-color: #8080806e
      }
      .van-cell{
        padding: 0;
        .van-cell__title{
          height: 36px;
          .title-s{
            display: flex;
            justify-content: space-between;
            .title-s-l{
              font-size: 13px;
              display: flex;
              .title-s-l-name{
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .title-s-r{
            }
          }
        }
      }
      .van-collapse-item__title--expanded{
        .van-cell__right-icon::before{
          color: @yellow-text
        }
        &::after {
          border-color: rgba(13,23,26,0.05);
          transform: scaleY(1.0);
          left: 0px;
          right: 0px
        }
      }
      .van-collapse-item__content{
        padding: 12px 0 0;
        .info{
          display: flex;
          color: rgba(13,23,26,0.45);
          font-size: 13px;
          margin-bottom: 12px;
          height: 30px;
          align-items: center;
          .info-r{
            height: 30px;
            display: flex;
            align-items: center;
            .avatar{
              margin-right: 10px
            }
            .sex{
              margin-left: 4px
            }
          }
        }
      }
    }
  }
</style>