<!-- 继承历史 -->
<template>
  <div class="history">
    <div class="common-block">
      <div class="title">
        <div class="title-l">
          <span>{{ dataSource.orgUserName }}</span>
          <img src="@/images/rightArrow-icon.svg"/>
          <span>{{ dataSource.newUserName}}</span>
        </div>
        <div class="title-r">
        </div>
      </div>
      <div class="content">
        <template v-if="checkHistoryType == 1">
          <div class="item">{{ $t('继承业务类型：') }}<span>{{ dataSource.bizType | typeFilter }}</span></div>
          <div class="item">
            <span>{{ dataSource.name }}</span>
            <span v-if="dataSource.mobile" class="mobile">{{ dataSource.mobile }}</span>
          </div>
        </template>
        <template v-else-if="checkHistoryType == 2">
          <div class="item" style="display: flex; align-items: center;height: 30px">
            <img class="avatar" :src="dataSource.avatar"/>
            <span class="wechatName">{{ dataSource.name }}</span>
            <img class="sex" :src="getSex(dataSource.gender)" width="14px" height="14px"/>
          </div>
          <div class="item">{{ $t('继承状态：') }}<span>{{ dataSource.status | statusFilter }}</span></div>
          <div class="item">{{ $t('完成时间：') }}
            <span v-if="dataSource.takeoverTime">{{ (dataSource.takeoverTime * 1000) | timeFormat('YYYY-MM-DD HH:mm:ss') }}</span>
            <span v-else>--</span>
          </div>
        </template>
        <template v-else>
          <div class="item">{{ $t('群名称：') }}<span>{{ dataSource.name }}</span></div>
          <div class="item">{{ $t('群人数：') }}<span>{{ `${dataSource.chatSize || '--'} ${$t('人')}` }}</span></div>
          <div class="item">{{ $t('继承状态：') }}<span>{{ dataSource.status | statusFilter }}</span></div>
        </template>
        <div class="item">{{ $t('分配人：') }}<span>{{ dataSource.operUserName || '--' }}</span></div>
        <div class="item">{{ $t('分配时间：') }}<span>{{ dataSource.createTime || '--' }}</span></div>
      </div>
    </div>
  </div>
</template>
<script>
let vm
import gender_woman from '@/images/gender_woman.png'
import noAvatar from '@/images/noAvatar.svg'
import gender_unknown from '@/images/gender_unknown.svg'
import gender_man from '@/images/gender_man.png'
export default {
  filters: {
    typeFilter(type) {
      if (!type) {
        return ''
      }
      return {
        1: vm.$t('线索'),
        2: vm.$t('订单'),
        3: vm.$t('交付'),
      }[type]
    },
    // 继承状态
    statusFilter(status) {
      if (!status) {
        return '--'
      }
      return {
        1: vm.$t('接替完毕'),
        2: vm.$t('等待接替'),
        3: vm.checkHistoryType == 2 ? vm.$t('客户拒绝') : vm.$t('失败'),
        4: vm.$t('接替成员客户达上限'),
      }[status]
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {}
    },
    checkHistoryType: {
      type: Number,
      default: () => 1
    }
  },
  data () {
    return {
      gender_woman,
      gender_man,
      gender_unknown,
      noAvatar,
    }
  },
  created() {
    vm = this
  },
  methods: {
    getSex(sex) {
      if (!sex) return gender_unknown
      const obj = {
        1: gender_man,
        2: gender_woman,
        0: gender_unknown,
      }
      return obj[sex]
    },
  }
}
</script>
<style lang="less" scoped>
  .history{
    .common-block{
      padding: 12px;
      .title{
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: self-start;
        font-size: 16px;
        color: @black;
        border-bottom: 1px solid rgba(13,23,26,0.05);
        .title-l{
          display: flex;
          align-items: center;
          img{
            margin: 0 6px;
            width: 20px;
          }
          span{
            display: inline-block;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .content{
        .item{
          height: 20px;
          line-height: 20px;
          color: rgba(13,23,26,0.45);
          font-size: 13px;
          margin-top: 10px;
          display: flex;
          &:not(:first-of-type) {
            margin-top: 4px;
          }
          .mobile{
            position: relative;
            padding-left: 16px;
            &::before{
              content: ' ';
              position: absolute;
              left: 8px;
              position: absolute;
              top: calc(50% - 6px);
              width: 2px;
              height: 12px;
              background: rgba(13,23,26,0.25);;
              border-radius: 0px 0px 0px 0px;
            }
          }
          .avatar{
            margin-right: 8px;
            width: 32px;
            height: 32px;
          }
          .sex{
            margin-left: 8px
          }
        }
      }
    }
  }
</style>