<template>
  <div ref="selectUser" class="SelectUser">
    <div class="searchBox">
      <van-search
        v-model.trim="searchStr"
        :maxlength="50"
        clearable
        class="search-input"
        :placeholder="$t('请输入员工名称')"
        @input="onInput"
        @search="onSearch"
        @clear="onSearch"
      />
    </div>
    <div class="content">
      <van-radio-group v-model="checkRadio">
        <van-radio v-for="(item, index) in userList" :key="index" :name="item.id">
          {{ item.name }}
        </van-radio>
      </van-radio-group>
    </div>
    <div class="common-footer" style="max-width: 100%;" >
      <van-button class="btn back" @click="goBack">{{$t('返回')}}</van-button>
      <van-button class="btn submit" :loading="submitLoading" @click="onSubmit">{{ $t('确定') }}</van-button>
    </div>
  </div>
</template>
<script>
import leaveJobServices from '@/services/leaveJob'
import { mapGetters } from 'vuex'
import { debounce } from '@/utils/index.js'
export default {
  data() {
    return {
      searchStr: '',
      submitLoading: false,
      userList: [],
      checkRadio: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    // 获取用户信息
    async getUserList() {
      let dealerId = ''
      this.userList = []
      this.checkRadio = ''
      // if (this.userInfo && this.userInfo.dealers && this.userInfo.dealers[0]){
      //   dealerId = this.userInfo.dealers.map(({ id }) => id).join(',')
      // }
      dealerId = this.selectedShopId || this.userInfo?.dealers[0]?.id
      const params = {
        dealerId,
        roleLogo: '',
        name: this.searchStr
      }
      const result = await leaveJobServices.getListEmpToMap(params)
      let arr = []
      for (var key in result) {
        arr = arr.concat(result[key])
      }
      this.userList = arr.map((item) => {
        return {
          ...item,
          text: `${item.name} ${[1,2].includes(this.checkBizType) ? this.$t('销售顾问') : this.$t('交付专员')}`
        }
      })
    },
    onInput:debounce(function() {
      this.getUserList()
    }),
    onSearch() {
      this.getUserList()
    },
    goBack() {
      this.checkRadio = ''
      this.$emit('cancel')
    },
    onSubmit() {
      if (!this.checkRadio) {
        this.$toast(this.$t('请选择员工！'))
        return false
      }
      this.$emit('confirm', this.userList.find(({ id }) => id === this.checkRadio))
      this.checkRadio = ''
    }
  }
}
</script>
<style lang="less" scoped>
  .SelectUser{
    height: 100%;
    padding-bottom: 96px;
    display: flex;
    flex-direction: column;
    .searchBox{
      background: #fff;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 12px 16px;
      box-shadow: 0px 2px 8px 2px #ddd;
      .search-input {
        padding: 0;
        border-radius: 20px;
        width: 100%;
        .van-search__content {
          background-color: #f5f5f5;
          border-radius: 24px;
        }
        /deep/ input::placeholder {
          color: rgba(13,23,26,0.45);
        }
        /deep/.van-icon-search{
          color: #666;
          font-size: 15px;
        }
        /deep/.van-field__left-icon{
          margin-right: 6px;
        }
      }
    }
    .content{
      flex: 1;
      overflow-y: scroll;
      padding: 16px;
      .van-radio{
        height: 44px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(13,23,26,0.05);
      }
    }
  }
</style>