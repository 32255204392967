<!-- 离职继承 -->
<template>
    <div class="leaveJob">
      <VanTabs :queryType="queryType" :tabList="tabList" lineWidth="60px" style="position: sticky; z-index: 20; top: 0;" @change="changeTab"></VanTabs>
      <template v-if="queryType == 4"> 
        <div class="searchBox" style="position: sticky; z-index: 20; top: 48px;">
          <SearchBarNew
            v-model="searchStr"
            :placeholder-text="$t('请输入离职员工名称')"
            :str="searchStr"
            @input="onInput"/>
          <div class="historyType">
            <div v-for="item in historyTypeList" :key="item.type" :class="['historyType-i', checkHistoryType == item.type ? 'active' : '']" @click="changeHistoryType(item)">
              {{ item.text }}
            </div>
          </div>
        </div>
        <List ref="list" :fetchApi="fetchApi">
          <template v-slot:default="{ item, index }">
            <History
              :dataSource="item"
              :checkHistoryType="checkHistoryType"
            />
          </template>
        </List>
      </template> 
      <template v-else>
        <div class="content">
          <van-pull-refresh v-model="isLoading" style="min-height: 100%;" @refresh="getDataList">
            <component :is="componentName" :queryMark="queryMark" :dataSource="dataSource" class="componentName" @dataChange="dataChange" ></component>
          </van-pull-refresh>
        </div>
        <div class="footer">
          <div style="line-height: 44px;">{{ `${$t('已选择')} ${checkData.length} ${$t('条')}` }}</div>
          <van-button class="btn submit" type="submit" :disabled="checkData.length == 0" @click="checkUser">{{ $t('选择人员') }}</van-button>
        </div>
      </template>
      <van-action-sheet v-model="showAction" >
        <div slot="default" class="contentSheet">
          <van-picker
            show-toolbar
            :columns="columns"
            style="width: 100%"
            @confirm="onConfirm"
            @cancel="onCancel">
          </van-picker>
        </div>
      </van-action-sheet>
      <van-popup
        v-model="showPopup"
        position="right"
        :style="{ height: '100%', width: '80%' }">
        <SelectUser
          ref="personPopup"
          @cancel="showPopup = false"
          @confirm="onConfirm">
        </SelectUser>
      </van-popup>
    </div>
</template>
<script>
import VanTabs from '@/components/VanTabs/index.vue'
import Work from './components/Work.vue'
import Chat from './components/Chat.vue'
import Group from './components/Group.vue'
import History from './components/History.vue'
import SelectUser from './components/SelectUser.vue'
import SearchBarNew from '@/components/search-bar-new'
import leaveJobServices from '@/services/leaveJob'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import List from '@/components/baseList/list.vue'
import { debounce } from '@/utils/index.js'
export default {
  components: {
    VanTabs,
    Work,
    Chat,
    Group,
    SelectUser,
    History,
    SearchBarNew,
    List,
  },
  data() {
    return {
      queryType: 1,
      componentName: 'work',
      tabList: [
        { text: this.$t('业务继承'), value: 1, hideCount: true, key: '', component: 'Work', listAxios: 'departBizList', transferAxios: 'departBizExtend' },
        { text: this.$t('好友继承'), value: 2, hideCount: true, key: '', component: 'Chat', listAxios: 'departExternalList', transferAxios: 'departExternalExtend' },
        { text: this.$t('群主继承'), value: 3, hideCount: true, key: '', component: 'Group', listAxios: 'departChatList', transferAxios: 'departChatExtend' },
        { text: this.$t('继承历史'), value: 4, hideCount: true, key: '', component: 'History', listAxios: 'departExtendHistory' },
      ],
      checkData: [],
      showAction: false,
      showPopup: false,
      columns: [],
      dataSource: [],
      isLoading: false,
      checkBizType: 'checkBizType',
      queryMark: false,
      searchStr: '',
      historyTypeList: [
        { text: this.$t('业务继承'), type: 1 },
        { text: this.$t('好友继承'), type: 2 },
        { text: this.$t('群主继承'), type: 3 },
      ],
      checkHistoryType: 1
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'selectedShopId'
    ]),
  },
  watch: {
    queryType: {
      handler(val) {
        if (val != 4) this.getDataList(val)
        // this.$refs['personPopup'].$refs['selectUser'].__vue__.checkRadio = ''
      },
      immediate: true
    }
  },
  methods: {
    // 获取数据源
    async getDataList() {
      this.dataSource = []
      this.queryMark = false
      const axiosUrl = this.tabList.find(({ value }) => value === this.queryType)?.listAxios
      loading.showLoading()
      const result = await leaveJobServices[axiosUrl]({})
      this.isLoading = false
      loading.hideLoading()
      this.queryMark = true
      // 好友和群继承 数据特殊处理
      if ([2,3].includes(this.queryType)) {
        result.forEach(item1 => {
          item1.datas && item1.datas.length > 0 && item1.datas.forEach(item2 => {
            item2.followUserName = item1.name
          })
        })
      }
      this.dataSource = result
    },
    // 获取人员数据
    async getUserList() {
      let dealerId = ''
      // if (this.userInfo && this.userInfo.dealers && this.userInfo.dealers[0]){
      //   dealerId = this.userInfo.dealers.map(({ id }) => id).join(',')
      // }
      dealerId = this.selectedShopId || this.userInfo?.dealers[0]?.id
      const params = {
        dealerId,
        roleLogo: [1,2].includes(this.checkBizType) ? ROLES.SALES : ROLES.DELIVERSALES,
      }
      const result = await leaveJobServices.getListEmpToMap(params)
      return result || {}
    },
    // 切换tab
    changeTab(val) {
      this.queryType = val
      this.checkData = []
      this.componentName = this.tabList.find(({ value }) => value === val)?.component
    },
    // 数据显示
    dataChange(data, checkBizType) {
      this.checkData = data
      this.checkBizType = checkBizType
      console.log(this.checkData)
    },
    // 选择人员
    async checkUser() {
      if (this.queryType == 1) {
        const data = await this.getUserList()
        let arr = []
        for (var key in data) {
          arr = arr.concat(data[key])
        }
        this.columns = arr.map((item) => {
          return {
            ...item,
            text: `${item.name} ${[1,2].includes(this.checkBizType) ? this.$t('销售顾问') : this.$t('交付专员')}`
          }
        })
        this.showAction = true
      } else {
        this.showPopup = true
      }
    },
    // 确定
    async onConfirm(data) {
      this.showAction = false
      this.showPopup = false
      if ([2,3].includes(this.queryType)) {
        this.checkData = this.checkData.map((item) => { return {
          ...item,
        } })
      }
      const params = {
        newUserId: data.id,
        newUserName: data.name,
        bizType: this.checkBizType,
        vos: this.checkData
      }
      loading.showLoading()
      const axiosUrl = this.tabList.find(({ value }) => value === this.queryType)?.transferAxios
      const res = await leaveJobServices[axiosUrl](params)
      loading.hideLoading()
      this.$toast.success(this.$t('操作成功！'))
      this.getDataList()
    },
    // 取消
    onCancel() {
      this.showAction = false
    },
    changeHistoryType({ type }) {
      this.checkHistoryType = type
      this.onRefresh()
    },
    // 获取列表数据
    async fetchApi(obj = {}) {
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        type: this.checkHistoryType,
        orgUserName: this.searchStr
      }
      loading.showLoading()
      try {
        const res = await leaveJobServices.departExtendHistory(params)
        loading.hideLoading()
        return res
      } catch {
        loading.hideLoading()
        return null
      }
    },
    onInput:debounce(function () {
      this.onRefresh()
    }, 500),
    onRefresh() {
      this.$refs.list.onRefresh()
    }
  }
}
</script>
<style lang="less" scoped>
.leaveJob{
  .searchBox{
    display: flex;
    flex-direction: column;
    .historyType{
      display: flex;
      justify-content: space-between;
      padding: 0 16px 12px 16px;
      background: #fff;
      .historyType-i{
        width: 109px;
        min-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 10px;
        border-radius: 24px 24px 24px 24px;
        border: 1px solid rgba(13,23,26,0.05);
        color: rgba(13,23,26,0.5);
        &.active{
          background: #EED484;
          border-radius: 24px 24px 24px 24px;
          border-color: EED484;
          color: @black
        }
      }
    }
  }
  .content{
    height: calc(100vh - 113px - env(safe-area-inset-bottom));
    padding: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  // &.leaveJob-pb{
  //   padding-bottom: calc(65px + env(safe-area-inset-bottom));
  //   .content{
  //     height: calc(100vh - 113px - env(safe-area-inset-bottom));
  //   }
  // }
  /deep/ .vanTabs-component{
    .van-tabs--line .van-tabs__wrap {
      height: 48px;
    }
    .van-tabs__nav{
      padding-bottom: 8px;
    }
  }
  .footer{
    max-width: 500px;
    width: 100%;
    height: calc(65px + env(safe-area-inset-bottom));
    display: flex;
    position: fixed;
    bottom: 0px;
    align-items: flex-start;
    background: #ffffff;
    box-sizing: border-box;
    padding-left: 16px;
    justify-content: space-between;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: @black;
    box-shadow: 0px 2px 8px 2px #ddd;
    .btn{
      width: 108px !important;
      background: #EED484;
      flex: inherit;
      height: 44px;
      border-radius: 0px
    }
  }
  /deep/ .contentSheet{
    display: flex;
    justify-content: center;
    .itemContent{
      display: flex;
      width: 100%;
      justify-content: center;
      line-height: 40px;
      &:hover{
        color:rgb(39, 124, 224)
      }
      span{
        margin-right: 10px;
      }
    }
  }
}
</style>